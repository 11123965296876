<template>
  <div>
    <Tabs :value="urlQuery.page" @on-click="changePage" class="centertabs">
      <TabPane :index="2" :label="'待处理'" name="waitDeal" v-if="roleCode !==0" />
      <TabPane :index="3" :label="'已提交'" name="submitted" v-if="roleCode !==6" />
      <TabPane :index="4" :label="'已发布'" name="published" />
    </Tabs>
    <WaitDealList v-if="urlQuery.page==='waitDeal'" />
    <PublishedList v-if="urlQuery.page==='published'" />
    <SubmitedList v-if="urlQuery.page==='submitted'" />
  </div>
</template>

<script >
import WaitDealList from './WaitDealList';
import PublishedList from './PublishedList';
import SubmitedList from './SubmitedList';

export default {
  components: { WaitDealList, PublishedList, SubmitedList },
  data() {
    return {
      urlQuery: {}
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    }
  },
  methods: {
    initFromRouter() {
      this.urlQuery = this.$route.query;
      // --- 完善默认url page页面：
      if (!this.urlQuery.page) {
        this.urlQuery = {
          page: this.roleCode === 0 ? 'submitted' : 'waitDeal'
        };
        this.refresh();
      }
    },
    refresh() {
      this.$router.replace({
        name: this.$route.name,
        query: this.urlQuery
      });
    },
    changePage(page) {
      if (page === this.urlQuery.page) {
        return;
      }
      this.urlQuery = { page };
      this.refresh();
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.initFromRouter();
  },
  mounted() {
    this.initFromRouter();
  }
};
</script>

<style scoped >
</style>
