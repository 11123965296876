<template>
  <div>
    <ListFrame
      :immediateInit="false"
      :getPageFn="getPage"
      :customedParams="myParams"
      :viewConf="{itemsDelAble:{delFn}}"
    >
      <template slot="conditions" slot-scope="{requestFn}">
        <ReceiveStatusSelect v-model="myParams.waitStatus" @input="requestFn"
          class="fl m-r-md"
          style="width:13em;"/>
        <Button class="fr m-r-md" type="primary" @click="requestFn">查询</Button>
      </template>
      <template slot-scope="{item}">
        <h3>{{item.title}}</h3>
        <span class="m-r-md">时间：<Time :time="item.createTime" type="datetime" /></span>
        <span>排序：{{item.sort}}</span>

        <ProcessSteps
          style="margin: 6px 0;font-size: 16px;width: 700px;"
          :taskName="item.taskName"
        />
        <!-- item.clip -->
        <ButtonsForWaitdealList :hb="item" :isLastView="$route.query.lastId===item.processInstanceId" @to-handle="toPage(item)"/>           
      </template>
    </ListFrame>
  </div>
</template>

<script>
import { ApiService } from '@/api/apiService';
import homeService from '@/api/homePageService';
export default {
  data(){
    return{ 
      myParams:{contentType:'enLongSentence',waitStatus: 2}
      }
    },
  methods: {
    getPage(params) {
      return ApiService.longSentenceList(params, 'waitdeal');
    },
    delFn(list, reason) {
      return homeService.deleteHbBatch(list, reason);
    },
    toPage(hb) {
      this.$router.replace({
        path: this.$route.path,
        query: Object.assign({}, this.$route.query, { lastId: hb.processInstanceId })
      });
      let taskId = hb.taskId;
      let bizId = hb.bizId;
      let processId = hb.processInstanceId;
      let contentType = hb.contentType;
      let taskName = hb.taskName;
      if (taskName === 6) {
        this.$router.push({
          path: '/lsverify6',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      console.error('长难句只有6审和编辑用户可以访问');
    }
  }
};
</script>

<style>
</style>