<template>
  <div>
    <ListFrame
      :immediateInit="false"
      :getPageFn="getPage"
      :viewConf="{      
      showShelStatus:true,
      showReorder:{show:false,initial:'1'},
      shelfOperateAble:operatable?{offshelfFn,onshelfFn}:false
      }"
      :customedParams="{contentType:'enLongSentence'}"
    >
      <template slot="conditions" slot-scope="{requestFn}">
        <Button class="m-r-md" type="primary" @click="requestFn">查询</Button>
      </template>
      <div slot-scope="{item}">
        <h3>{{item.title}}</h3>
        <span v-if="item.workFlowMap.publishTime" class="m-r-md">
          发布时间：
          <Time :time="item.workFlowMap.publishTime" type="datetime" />
        </span>
        <span>排序：{{item.sort}}</span>
        <div>
          <Button type="warning" @click="preview(item)">预览</Button>
          <span class="status-span active" v-if="item.status==3">已上架</span>
          <span class="status-span" v-else>
            已下架
            <span style="padding-left:1em">下架原因：{{item.remark}}</span>
          </span>
        </div>
        <!-- {{item.workFlowMap}} -->
      </div>
    </ListFrame>    
  </div>
</template>

<script>
import homeService from '@/api/homePageService';
import { ApiService } from '@/api/apiService';
import { getSubmitedInfo } from '@/api/hbService';
export default {
  data() {
    return {
    };
  },
  computed: {
    operatable() {
      return true;
    }
  },

  methods: {
    getPage(params) {
      return ApiService.longSentenceList(params, 'published').then((res) => res.list);
    },
    offshelfFn(list, reason) {
      return homeService.offShelf(list, reason);
    },
    onshelfFn(list) {
      return homeService.onShelf(list);
    },
    preview(item) {
      getSubmitedInfo({
        bizId: item.id,
        taskName: this.$store.getters.getRoleCode,
        contentType: item.contentType
      }).then((res) => {     
        this.$mobilePreview(res.content)
      });
    }
  }
};
</script>

<style scoped>
</style>