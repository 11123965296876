<template>
  <div>
    <ListFrame :immediateInit="false" :getPageFn="getPage" :customedParams="{contentType:'en'}">
      <div slot-scope="{item}">
        <h3>{{item.contentTitle&&item.contentTitle[0]}}</h3>
        <span class="m-r-md"> 时间：{{item.subTime | dateFormat}}</span>
        <span>排序：{{item.sort}}</span>
       
        <ProcessSteps
          style="margin: 6px 0;font-size: 16px;width: 700px;"
          :taskName="item.nowTaskName"
        />
      </div>
      <template slot="conditions" slot-scope="{requestFn}">
        <Button class="m-r-md" type="primary" @click="requestFn">查询</Button>
        <router-link class="text-primary m-r-md m-t-xs" to="/edit4lsEditor">
          <Icon type="md-add-circle" style="font-size:1.2em" />长难句
        </router-link>
      </template>
    </ListFrame>
  </div>
</template>

<script>
import { ApiService } from '@/api/apiService';
export default {
  methods: {
    getPage(params) {
      return ApiService.longSentenceList(params, 'history');
    }
  },
  beforeMount() {}
};
</script>

<style>
</style>
